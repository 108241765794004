<template>
    <div class="app-body">
        <div class="a-flex-rsbc">
            <bread-crumb></bread-crumb>
            <!-- <span class="a-fs-16 a-fw-700">套餐账单详情</span> -->
        </div>
        <el-card class="el-main">
            <div id="leSearch"></div>
            <le-pagview ref="financePackageDetails" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.financePackageDetailsList">
                <el-table ref="financePackageDetailsList" :data="tableData" :highlight-current-row="true" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="mobile" label="用户手机号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.mobile?row.mobile:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userId" label="用户ID" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.userId?row.userId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="monthcardName" label="套餐名称" min-width="150">
                        <template slot-scope="{ row }">
                            <span>{{ row.monthcardName?row.monthcardName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="userMonthcardId" label="套餐ID" min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.userMonthcardId?row.userMonthcardId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderId" label="订单编号" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderId?row.orderId:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="stationName" label="站点名称" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.stationName?row.stationName:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="term" label="当期/总期" min-width="120">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-cfsfs">
                                <span class="a-fs-12">订单当期/订单总期/套餐当期</span>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <span>{{ scope.row.term?scope.row.term:'-' }}/{{ scope.row.totalTerm?scope.row.totalTerm:'-' }}/{{ scope.row.monthCardTerm?scope.row.monthCardTerm:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderPrice" label="订单总金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payAmount" label="实付金额" min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.payAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="currentRecordAmount" label="本期入账金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.currentRecordAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="recordAmount" label="订单已入账金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.recordAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="monthCardRecordAmount" label="套餐已入账金额" min-width="120">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.monthCardRecordAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="支付渠道" 
                        min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.payChannel | initDic(payChannelDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        prop="myOrderNum" 
                        label="完结状态" 
                        min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.status | initDic(statusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="serviceStart" label="服务开始时间" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.serviceStart?row.serviceStart:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="serviceEnd" label="服务结束时间" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.serviceEnd?row.serviceEnd:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="updateTime" label="分账日期" min-width="180">
                        <template slot-scope="{ row }">
                            <span>{{ row.updateTime?row.updateTime:'-' }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.reconciliationDetail,
                    method: "post",
                    params: {
                        status: '',
                        payChanel: '',
                        id: this.$route.query.id
                    },
                    freshCtrl: 1,
                },
                payChannelDic: [],//支付方式
                statusDic: [],
            }
        },
        created () {
            this.$getDic('monthCardRecType').then(res=>{ this.statusDic = res; })
            this.$getDic('payChannel').then(res=>{ this.payChannelDic = res; })
        },
        mounted () {
            
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                // this.pageParam.params = {
                //     isConfirm: '',
                //     companyIds: [],
                //     balanceDateStart: '',
                //     balanceDateEnd: '',
                // };
                // this.handlerSearch()
            },
            handlerSearch() {
                // this.$refs['financePackageDetails'].pageNum = 1
                // this.pageParam.freshCtrl++;
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='status'){
                        if(value[key].length==0){
                            this.pageParam.params.status = ''
                        }else{
                            this.pageParam.params.status = value[key][0]
                        }
                    }
                    if(key=='payChannel'){
                        if(value[key].length==0){
                            this.pageParam.params.payChanel = ''
                        }else{
                            this.pageParam.params.payChanel = value[key][0]
                        }
                    }
                }
                this.$refs['financePackageDetails'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>